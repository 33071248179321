<template>
  <div class="hello">
    <h3 @click="show">Ecosystem</h3>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  methods: {
    show() {
      const axiosInstance1 = this.$http.create({
        baseURL: "http://wxgzh.szzhaoyun.com/aftersalesapi/",
        withCredentials: true, // 跨域请求时发送Cookie
        timeout: 30000, // 请求超时
      });
      axiosInstance1({
        url: "api/user/v2/wx_login",
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
